export const styleButtonSearch = { borderTopRightRadius: 8, borderBottomRightRadius: 8, cursor: 'pointer' }
export const styleButtonSearchLeft = { borderTopLeftRadius: 8, borderBottomLeftRadius: 8, cursor: 'pointer' }
export const styleButtonPlus = { borderRadius: 8 }
export const styleVideo = {
	WebkitTransform: 'scaleX(-1)', transform: 'scaleX(-1)'
}
export const styleCanvas = {
	WebkitTransform: 'scaleX(-1)', transform: 'scaleX(-1)'
}
export const styleImage = {
	maxWidth: 300, maxHeight: 300,
}
export const stylesReport = {
	page: {
		flexDirection: 'column',
		backgroundColor: 'white',
		padding: 20,
		fontFamily: 'Helvetica',
	},
	section: {
		margin: 10,
		padding: 10,
		flexGrow: 1
	},
	header: {
		display: 'flex',
		flexDirection: 'row'
	},
	headerImage: {
		width: '40%',
	},
	headerText: {
		width: '50%',
		display: 'flex',
		flexDirection: 'column',
		padding: 20
	},
	line: {
		display: 'flex',
		flexDirection: 'row',
	},
	smallText: {
		fontSize: 14,
		marginBottom: 3
	},
	verySmallText: {
		fontSize: 10,
		marginBottom: 3
	},
	smallHeaderText: {
		fontSize: 16,
		width: '100%',
		color: '#9a9a9a',
		textAlign: 'right'

	},
	breakLine: {
		marginTop: 10
	},
	box: {
		border: '1px solid #9a9a9a',
		borderRadius: 10,
		padding: 10
	},
	photoBox: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-around',
		aligItems: 'center',
		flexWrap: 'wrap',
	},
	photoItem: {
		display: 'flex',
		flexDirection: 'column',
		aligItems: 'center',
		justifyContent: 'center',
		maxWidth: '50%',
		marginBottom: 3
	},
	imgPhoto: {
		height: 240,
		width: 426,
		borderRadius: 10
	},
	invisible: {
		display: 'none'
	},
	visible: {
		display: 'block',
		width: '100%',
		marginLeft: 15,
		marginTop: 5
	}
}