import React from 'react';
import FormReport from '../form/report';

const FilterSiderBar = ({ toggleSidebar, buttonsReport }) => {
	return (
		<aside className="fixed-right">
			<div className="sidebar p-3">
				<div className='col d-flex justify-content-between'>
					<h4>Filtros</h4>
					<p onClick={toggleSidebar} style={{color:'#aaa', cursor:'pointer'}}>Fechar</p>
				</div>
				<FormReport />
				{buttonsReport}
			</div>
		</aside>
	);
};

export default FilterSiderBar;