import React from 'react'
import RenderTH from './RenderTH'
const ReachedTable = ({ items, handleEdit = null, handleDelete = null }) => {
	if (!items?.length) {
		<div className="text-secondary">
			<p>Não há itens para serem exibidos</p>
		</div>
	}
	return (
		<div className="table-responsive">
			<table className="table table-bordered">
				<thead>
					<tr>
						<th scope="col">Ac.</th>
						<th scope="col">Ac. Ant.</th>
						<th scope="col">Progresso</th>
						<th scope="col">Quando</th>
						{handleEdit ?
							(<th></th>)
							: null}
					</tr>
				</thead>
				<tbody>
					{items.map((item, i) => (
						<RenderTH
							item={item}
							key={i}
							handleEdit={handleEdit}
							handleDelete={handleDelete}
						/>
					))}
				</tbody>
			</table>
		</div>
	)
}
export default ReachedTable