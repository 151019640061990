import React, { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import ContentHeader from '../../../components/contentHeader'
import Spinner from '../../../components/spinner'
import { LOGO, RELATORIO_LOGO } from '../../../config/flavor'
import { requestContext } from '../../../context/requestContext'
import { formatDate } from '../../../services/dateHandless'
import groupBy from '../../../services/groupBy'
import { numberInWords } from '../../../services/formateNumber'
import { handleWaring } from '../../../services/isValid'
import { generateImage } from '../../../services/files'
import RoutineConservationReport from '../../../components/cards/routineConservationReport'
import { filterAndRemove, filterAndRemoveRepeated } from '../../../services/arrayHandless'
import { stylesReport } from '../../../css/globlalStyle'

const NewNotification = () => {
	const { setLoading, loading, postItemNotification } =
		useContext(requestContext)
	const [subStringYear, setSubStringYear] = useState('')
	const [data, setData] = useState(null)
	const location = useLocation()
	const navigate = useNavigate()
	const [print, setPrint] = useState(false)
	const [homogeneousStretch, setHomogeneousStretch] = useState(null)
	useEffect(() => {
		getYear()
		if (location) {
			const { state } = location
			if (Array.isArray(state)) {

				const group = groupBy(filterAndRemoveRepeated(state), 'pvs_servico_sub_cat')
				console.log('esse', group)
				setData(group)
			}
		}
	}, [location])
	useEffect(() => {
		if (print) {
			window.print()
			handleCancel()
		}
	}, [print])
	const handleCancel = () => {
		navigate('/oversight')
	}
	const getYear = () => {
		const date = new Date().getFullYear()
		const string_year = date.toString()
		const year = string_year.substring(2, 4)
		setSubStringYear(year)
	}
	const handleSubmit = async () => {
		try {
			const { state } = location

			const item = filterAndRemoveRepeated(state.map((item) => ({
				not_pvs: item.pvs_cod,
				contrato: item.not_cont_cod,
				infracao: item.not_inf_cod,
				prazo: item.praz_dias?.not_praz_dias ? item.praz_dias?.not_praz_dias : ""
			})))

			const not_base64 = await generateImage(
				document.getElementById('notification')
			)

			window.scrollTo(0, 0)
			postItemNotification({
				not_pvs: item,
				not_base64,
			}).finally(() => {
				setPrint(true)
				setLoading(false)
			})
		} catch (error) {
			handleWaring(String('Não foi possível completar sua solicitação'))
		}
	}
	const CheckBoxHomogeneousStretch = ({ i, length }) => {
		let checked_ = false
		let disabled = false
		const verifyPossibleHomgoneous = () => {
			if (length <= 1) {
				disabled = true
			}
		}
		const verifyChecked = () => {
			if (!Array.isArray(homogeneousStretch)) {
				return checked_ = false
			}
			const findItem = homogeneousStretch.find(el => parseInt(el.value) === parseInt(i))
			checked_ = findItem
		}
		const onChange = ({ target }) => {
			const { value, checked } = target
			checked_ = checked
			if (!checked) {
				const result = filterAndRemove(
					homogeneousStretch,
					'value',
					value,
				)
				return setHomogeneousStretch(result.data)
			}
			setHomogeneousStretch(
				Array.isArray(homogeneousStretch)
					? [...homogeneousStretch, { value }]
					: [
						{
							value
						},
					]
			)
		}
		verifyChecked()
		verifyPossibleHomgoneous()
		return (
			<div className="form-check">
				<input className="form-check-input" type="checkbox" value={i} id={i}
					disabled={disabled} checked={checked_}
					onChange={onChange} />
				<label className="form-check-label" htmlFor={i}>
					É um trecho homogênio?
				</label>
			</div>
		)
	}
	const NumberNotification = ({ item, i }) => {
		if (!Array.isArray(homogeneousStretch)) {
			return (
				<>
					{Array.isArray(data[item]) &&
						`${data[item].length} (${numberInWords(
							data[item].length
						)})`}{' '}
				</>
			)
		}
		if (homogeneousStretch.find(el => parseInt(el.value) === parseInt(i))) {
			return (
				<>
					1 (uma){' '}
				</>
			)
		}
		return (
			<>
				{Array.isArray(data[item]) &&
					`${data[item].length} (${numberInWords(
						data[item].length
					)})`}{' '}
			</>
		)
	}
	return (
		<>
			{!print && (
				<ContentHeader title={'Notificando a concessionária'} />
			)}
			<Spinner direction={'center'} />
			<div className="content" id="notification">
				{data && Object.keys(data).map((item, i) => (
					<div className="card" key={i}>
						{!print && (
							<div className="card-header">
								<div className="row">
									<div className="col-md-8 col-sm-12">
										<div className="card-title d-none d-md-block">Notificação referente a atividade {data[item].map(({ serv_sub_cat_desc }, index) => index === 0 && serv_sub_cat_desc)}</div>
									</div>
									<div className="col-md-4 col-sm-12">
										<CheckBoxHomogeneousStretch i={i} length={data[item]?.length} />
									</div>
								</div>
							</div>
						)}
						<div className="card-body">
							<div className='d-flex justify-content-between'>
								<img src={RELATORIO_LOGO} alt="image_logo" style={{
									maxWidth: 250,
								}} />
								<div style={stylesReport.headerText}>
									<p style={stylesReport.smallHeaderText}>Notificação de infração</p>
									<p style={stylesReport.smallHeaderText}>Gerada no dia {formatDate()}</p>
								</div>
							</div>
							<p className="text-right">
								{' '}
								NOT.DIN.
								{data[item].map(({ ped_venda_cod }) => `${ped_venda_cod.toString()
									.padStart(4, '0')}/${subStringYear}`)
								}
							</p>
							<h3 className="text-center">
								NOTIFICAÇÃO DE INFRAÇÃO ADMINISTRATIVA
							</h3>
							<p className="text-justify">
								A Agência Reguladora de Serviços Públicos Delegados de
								Transporte do Estado de São Paulo - ARTESP, no uso de suas
								atribuições, conferidas pelo artigo 4º da Lei Complementar n°
								914, de 14 de janeiro de 2002, regulamentada pelo Decreto
								Estadual n° 46.708, de 22 de abril de 2002, por meio da
								Diretoria de Investimentos, nos termos do Artigo 36, Incisos X
								e XII, do Regimento Interno, e de acordo com as obrigações
								previstas no Contrato de Concessão nº <span className="success ml-1">
									{data[item].map(({ not_cont_nome }, i) => i === 0 && not_cont_nome)}
								</span>, celebrado em{' '}
								<span className="success ml-1">
									{data[item].map(({ data_contrato }, index) => index === 0 && formatDate(data_contrato))}
								</span>
					 em seus Anexos e em Termo(s) Aditivo(s) e Modificativo(s),
								quando aplicável, INSTAURA Processo Administrativo
								Sancionatório e NOTIFICA a <span className="danger ml-1">
									{data[item].map(({ conc_razaosocial }, index) => index === 0 && conc_razaosocial)}</span> da prática de
								Infração Administrativa por
								{data[item].map(({ servico_nao_executado }, index) => <span key={index} className="warning ml-1">{servico_nao_executado}
									{index > 1 ? ',' : ''}
								</span>)}
					 estando sujeita à penalidade prevista{' '}
								{Array.isArray(data[item]) && data[item].map(({ anexo_penalidade }, index) => (index === 0 && <span key={i} className="danger ml-1">
									{anexo_penalidade || 'Sem penalidade prevista'}
								</span>))}{Array.isArray(data[item]) &&
									data[item].map(({ tipificacao }, index) => (
										index === 0 &&
										<span key={i} className="info ml-1">
											{tipificacao === 'Sem' ? `${tipificacao} tipificação` : tipificacao}
										</span>
									))}.
							</p>
							<p className="text-justify">
								A <span className="danger ml-1">{data[item].map(({ conc_razaosocial }, index) => index === 0 && conc_razaosocial)}</span> celebrou
								contrato com o Poder Concedente, obrigando-se a zelar por
								integridade dos bens que integram a concessão e áreas
								remanascentes, incluindo as que se referem à faixa de domínio
								e aos seus acessos, nos termos e prazos estabelecidos em
								Contrato de Concessão. No entanto, conforme documento(s)
								anexo(s), que integra(m) o presente ato, verificou-se a não
								execução injustificada ou a execução insatisfatória de serviço
								relativo ao item {Array.isArray(data[item]) && data[item].map((element, i) => (
									!element.numero_item_anexo ? (
										<div className="input-group d-line" key={i}>
											<input type="text"
												className="form-control form-control-border"
												placeholder='Como não há item descreva o item aqui' />
										</div>
									) :
										(<span key={i}>
											<span className="warning" key={i}>
												{element.numero_item_anexo || element.numero_ocorrencia}
											</span> do <span className="danger">{element.anexo_infracao}</span>,
											constituindo
										</span>)
								))}{' '}
								<span className="warning">
									<NumberNotification i={i} item={item} />
									(
									{Array.isArray(data[item]) &&
										data[item].map((el, i) => `Não Conformidade n°: ${el.ped_venda_cod}${i + 1 !== data[item].length ? ', ' : ''}`
										)}
									)
								</span>{' '}
								infração(ões)
								.
							</p>
							<p className="text-justify">
								Não obstante, independentemente da penalidade supramencionada,
								devido ao prazo inicial concedido e não atendido, fica a
								concessionária ciente que, a contar do recebimento desta
								notificação, para cada dia de atraso na execução dos serviços
								de regularização da(s) situação(ões) não conforme(s),
								abrangida(s) pelo(s) documento(s) anexo(s), acarretará
								cobrança de multa moratória à razão de 1% ao dia sobre o valor
								da penalidade aplicada, até o cumprimento da obrigação,
								conforme disposição da cláusula{' '}
								{Array.isArray(data[item]) && data[item].map(({ multa_moratoria }, i) => (
									<span className="warning" key={i}>
										{multa_moratoria}
									</span>
								))}
								.
							</p>
							<p className="text-justify">
								Face ao exposto, INTIME-SE a{' '}
								<span className="danger ml-1">{data[item].map(({ conc_razaosocial }, index) => index === 0 && conc_razaosocial)}</span> para que
								apresente Defesa Prévia e indique as provas que pretende
								produzir, no prazo de 15 dias contados do recebimento desta
								notificação, nos termos do Artigo 63, Inciso III da Lei
								Estadual nº 10.177 de 30 de dezembro de 1998, instruída com a
								documentação que julgar necessária, dirigida à ARTESP e
								protocolada no Centro de Documentação (Setor de Protocolo).
							</p>
						</div >
						<RoutineConservationReport data={data} item={item} />
					</div >
				))}
			</div>
			{!print && (
				<div className="container-fluid">
					<div className="card">
						<div className="card-body">
							{loading ? (
								<button className="btn btn-info mr-3">
									Gerando{' '}
									<div
										className="spinner-border spinner-border-sm"
										role="status"
									/>
								</button>
							) : (
								<button className="btn btn-info mr-3" onClick={handleSubmit}>
									Gerar <i className="fa fa-download"></i>
								</button>
							)}
							<div className="btn btn-secondary" onClick={handleCancel}>
								Cancelar <i className="fa fa-times-circle"></i>
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	)
}
export default NewNotification
