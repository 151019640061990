import React, { useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { namesScreens } from '../../../constants'
import { authContext } from '../../../context/authContext'
const TabsConstructionPlan = ({
	setTabServices,
	setTabGraphics,
	setTabTimeLine,
	setTabInfo,
	setTabAttachments,
	setTabMidias,
	setTabReachedMilestone,
	tabclassName
}) => {
	const [active, setActive] = useState('setTabServices')
	const { user } = useContext(authContext)
	const { pathname } = useLocation()
	useEffect(() => {
		const controllActiveDefault = () => {
			if (pathname === namesScreens.CONSTRUCTION_PLANNEW) {
				return setActive('setTabInfo')
			}
			return setActive('setTabServices')
		}
		controllActiveDefault()
	}, [pathname])
	const onClick = id => {
		if (
			!setTabServices ||
			!setTabGraphics ||
			!setTabTimeLine ||
			!setTabInfo ||
			!setTabAttachments ||
			!setTabMidias ||
			!setTabReachedMilestone
		) {
			return
		}
		setActive(id)
		setTabServices('')
		setTabGraphics('')
		setTabTimeLine('')
		setTabInfo('')
		setTabAttachments('')
		setTabMidias('')
		setTabReachedMilestone('')
		switch (id) {
			case 'setTabServices':
				setTabServices(tabclassName)
				break
			case 'setTabGraphics':
				setTabGraphics(tabclassName)
				break
			case 'setTabTimeLine':
				setTabTimeLine(tabclassName)
				break
			case 'setTabInfo':
				setTabInfo(tabclassName)
				break
			case 'setTabAttachments':
				setTabAttachments(tabclassName)
				break
			case 'setTabMidias':
				setTabMidias(tabclassName)
				break
			case 'setTabReachedMilestone':
				setTabReachedMilestone(tabclassName)
				break
			default:
				break
		}
	}
	return (
		<ul className="nav nav-tabs" id="myTab" role="tablist">
			<li className="nav-item" role="presentation">
				<button onClick={() => onClick('setTabServices')} className={active === '' ? 'nav-link active' : 'nav-link'} id="services-tab" data-toggle="tab" data-target="#services" type="button" role="tab" aria-controls="services" aria-selected="true">Serviços</button>
			</li>
			<li className="nav-item" role="presentation">
				<button onClick={() => onClick('setTabGraphics')} className={active === '' ? 'nav-link active' : 'nav-link'} id="graphics-tab" data-toggle="tab" data-target="#graphics" type="button" role="tab" aria-controls="graphics" aria-selected="false">Gráficos</button>
			</li>
			<li className="nav-item" role="presentation">
				<button onClick={() => onClick('setTabTimeLine')} className={active === '' ? 'nav-link active' : 'nav-link'} id="timeline-tab" data-toggle="tab" data-target="#timeline" type="button" role="tab" aria-controls="timeline" aria-selected="false">Cronograma</button>
			</li>
			<li className="nav-item" role="presentation">
				<button onClick={() => onClick('setTabInfo')} className={active === '' ? 'nav-link active' : 'nav-link'} id="info-tab" data-toggle="tab" data-target="#info" type="button" role="tab" aria-controls="info" aria-selected="false">Informações adicionais</button>
			</li>
			<li className="nav-item" role="presentation">
				<button onClick={() => onClick('setTabAttachments')} className={active === '' ? 'nav-link active' : 'nav-link'} id="attachments-tab" data-toggle="tab" data-target="#attachments" type="button" role="tab" aria-controls="attachments" aria-selected="false">Anexos (Doc, Planilhas e etc.)</button>
			</li>
			<li className="nav-item" role="presentation">
				<button onClick={() => onClick('setTabMidias')} className={active === '' ? 'nav-link active' : 'nav-link'} id="midias-tab" data-toggle="tab" data-target="#midias" type="button" role="tab" aria-controls="midias" aria-selected="false">Fotos e Vídeos</button>
			</li>
			{user.set_nivel === 0 ? (
				<li className="nav-item" role="presentation">
					<button onClick={() => onClick('setTabReachedMilestone')} className={active === '' ? 'nav-link active' : 'nav-link'} id="reachedMilestone-tab" data-toggle="tab" data-target="#reachedMilestone" type="button" role="tab" aria-controls="reachedMilestone" aria-selected="false">
						Marco(s) Atingido(os)
					</button>
				</li>
			) : null}
		</ul>
	)
}
export default TabsConstructionPlan