import React, { useContext, useEffect, useState } from 'react'
import { formContext } from '../../../context/formContext'
import { handleWaring, isValid } from '../../../services/isValid'
import { requestContext } from '../../../context/requestContext'
import Spinner from '../../spinner'
import { formatDate } from '../../../services/dateHandless'
const FormApprovedRecusedProgress = ({ closeModal }) => {
	const { state, onChange } = useContext(formContext)
	const { setInfoRequest, postItemApproveProgress, getItemCalcMemorySpreadSheet } = useContext(requestContext)
	const [approved, setApproved] = useState(false)
	const [placeholder, setPlaceHolder] = useState(null)
	const {
		pl_ob_cod,
		pvs_pedidovenda,
		prog_serv_qtd_acul,
		fisc_cod,
		fisc_current_obs,
		prog_serv_cod,
		serv_sub_cat_desc,
		fisc_obs,
		updated_at
	} = state
	useEffect(() => {
		const controllPlaceHolder = () => {
			if (!serv_sub_cat_desc) {
				return
			}
			setPlaceHolder(`Descreva o porque você ${approved ? 'aprova' : 'não aprova'} essa alteração no serviço ${serv_sub_cat_desc}`)
		}
		controllPlaceHolder()
	}, [serv_sub_cat_desc])

	const handleSave = async () => {
		const item = {
			pvs_pedidovenda,
			prog_serv_qtd_acul,
			approved: !approved ? 0 : 1,
			fisc_cod,
			prog_serv_cod,
		}
		const valid = isValid(item)
		if (!valid) {
			return setInfoRequest(handleWaring('Você precisa preencher todos os campos obrigatórios'))
		}
		if (!approved && String(fisc_current_obs)?.length <= 4) {
			return setInfoRequest(handleWaring('Você precisa explicar o porquê não está aprovando essa fiscalização'))
		}
		if (!approved) {
			item.fisc_obs = fisc_current_obs
		}
		const response = await postItemApproveProgress({
			id: fisc_cod,
			pl_ob_cod,
			...item,
		})
		if (closeModal && response) {
			closeModal()
		}
	}
	const handleDowloadCalcMemorySpreedSheet = async () => {
		if (!prog_serv_cod) {
			return setInfoRequest(handleWaring(`Não foi possível obter o código do serviço ${serv_sub_cat_desc}`))
		}
		await getItemCalcMemorySpreadSheet(prog_serv_cod)
	}
	return (
		<div className="row">
			<div className="form-group col-12">
				<label><i className="fa fa-info-circle mr-2" aria-hidden="true"></i> Fiscalização atual {formatDate(updated_at)}:</label>
				<div className="input-group">
					<textarea
						type="text"
						name="fisc_obs"
						value={fisc_obs}
						disabled
						className="form-control" />
				</div>
			</div>
			<div className="form-group col-12">
				<div className='row'>
					<div className='col-md-4 col-sm-6'>
						<button className='btn btn-sm btn-info' onClick={() => setApproved(true)}>
							Aprovar {approved ? (<i className="fa fa-check-circle" aria-hidden="true"></i>
							) : ''}
						</button>
					</div>
					<div className='col-md-4 col-sm-6'>
						<button className='btn btn-sm btn-secondary' onClick={() => setApproved(false)}>
							Não aprovar {!approved ? (<i className="fa fa-check-circle" aria-hidden="true"></i>
							) : ''}
						</button>
					</div>
					<div className='col-md-4 col-sm-12'>
						<button
							className='btn btn-dark btn-sm mr-2'
							onClick={handleDowloadCalcMemorySpreedSheet}
						>
							<i className='fa fa-download'></i> Memória de Cálculo
						</button>
					</div>
				</div>
			</div>
			<div className="form-group col-12">
				<label>Observações sobre essa fiscaliazção:</label>
				<div className="input-group">
					<textarea
						type="text"
						name="fisc_current_obs"
						onChange={onChange}
						className="form-control"
						minLength={5}
						maxLength={100}
						placeholder={placeholder} />
				</div>
			</div>
			<div className="col-12">
				<button className="btn btn-info" onClick={handleSave}>
					<i className="fa fa-save"></i> Salvar <Spinner direction={'center'} label={' '} />
				</button>
			</div>
		</div >
	)
}
export default FormApprovedRecusedProgress