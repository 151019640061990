import React, { useContext, useEffect, useRef, useState } from 'react'
import { formContext } from '../../../context/formContext'
import SelectTypeAttachmentConstructionPlan from '../selectTypeAttachmentConstructionPlan'
import { Link } from 'react-router-dom'
import { handleError, handleWaring, isValid } from '../../../services/isValid'
import { requestContext } from '../../../context/requestContext'
import getLatLng from '../../../services/geolocation'
import { generateBase64, getExt } from '../../../services/files'
import { allowedImageExtensions, allowedXlsExtensions } from '../../../constants'
import { getUniqueRandomInt } from '../../../services/randomId'
import { filter, find, groupBy } from '../../../services/arrayHandless'
import ListAttachmentsContructionPlan from '../../list/attachmentsContructionPlan'
import { generateDateTimeStampJS } from '../../../services/dateHandless'
import { getPartTextFromCharacter } from '../../../services/stringHandless'
import { loadConfigWhichPartToTake } from '../../../services/constructionPlan'
import FormSelectDiscipline from '../selectDiscipline'
const FormaAttachmentsConstructionPlan = ({ closeModal, isPressed }) => {
	const input = useRef(null)
	const { state, onChange, handleForm } = useContext(formContext)
	const { setInfoRequest, postItemAttachmentsConstructionPlan, getItemTypeAttch, service, contract_Attachment } = useContext(requestContext)
	const [files, setFiles] = useState([])
	const [serviceItems, setServiceItems] = useState([])
	const [servicesSubCat, setServicesSubCat] = useState([])
	const [services, setServices] = useState([])
	const [whichPartToTake, setWhichPartToTake] = useState(2)
	useEffect(() => {
		const loadConfig = async () => {
			const value = await loadConfigWhichPartToTake()
			if (!value) {
				return
			}
			setWhichPartToTake(value)
		}
		loadConfig()
	}, [])
	const {
		ane_pl_ob_cod,
		ane_pl_ob_obra_cod,
		ane_pl_ob_path,
		ane_pl_ob_obs,
		pl_ob_cod,
		ane_pl_ob_tipo,
		ane_pl_ob_pvs_cod,
		tip_ane_desc,
	} = state
	useEffect(() => {
		if (!ane_pl_ob_obra_cod) {
			handleForm({
				ane_pl_ob_obra_cod: pl_ob_cod
			})
		}
	}, [ane_pl_ob_obra_cod])
	const filterServiceSubCat = ({
		target: { value }
	}) => {
		if (!value || !serviceItems?.length) {
			return
		}
		const { data: filteredData } = filter(serviceItems, 'gp_cod', value)
		setServicesSubCat([])
		handleForm({
			ane_pl_ob_pvs_cod: '',
		})
		setServicesSubCat(filteredData)
	}
	useEffect(() => {
		setFiles([])
	}, [isPressed])
	const controllItems = () => {
		try {
			if (Array.isArray(service?.data)) {
				const { data } = service
				const itemsWithGpCod = data.map(item => ({
					serv_desc: item.serv_desc,
					pvs_servico: item.pvs_servico,
					prog_serv_obra: item.prog_serv_obra,
					gp_cod: getPartTextFromCharacter({
						input: item.serv_sub_cat_subitem_der,
						joinString: '.',
						whichPartToTake,
					})
				}))
				const groupedItems = groupBy(itemsWithGpCod, 'gp_cod')
				const itemsFromServices = []
				Object.keys(groupedItems).forEach(item => {
					itemsFromServices.push(groupedItems[item][0])
				})
				setServices(itemsFromServices.sort((a, b) => {
					const [a1, a2] = a.gp_cod.split('.').map(Number)
					const [b1, b2] = b.gp_cod.split('.').map(Number)
					return a1 - b1 || a2 - b2
				}))
				const serviceSubcatGPSorted = data.sort((a, b) => {
					const [a1, a2] = a.pvs_obs.split('.').map(Number)
					const [b1, b2] = b.pvs_obs.split('.').map(Number)
					return a1 - b1 || a2 - b2
				}).map(item => ({
					...item,
					gp_cod: getPartTextFromCharacter({
						input: item.pvs_obs,
						joinString: '.',
						whichPartToTake,
					})
				}))
				setServicesSubCat(serviceSubcatGPSorted)
				setServiceItems(serviceSubcatGPSorted)
			}
			if (Array.isArray(contract_Attachment?.data) && ane_pl_ob_tipo) {
				const { data } = contract_Attachment
				const { data: findTipDesc } = find(data, 'tip_ane_cod', ane_pl_ob_tipo)
				handleForm({
					tip_ane_desc: findTipDesc?.tip_ane_desc
				})
			}
		} catch (error) {
			console.log(error)
		}
	}
	useEffect(() => {
		controllItems()
	}, [service, contract_Attachment, ane_pl_ob_tipo, whichPartToTake])
	useEffect(() => {
		getItemTypeAttch()
	}, [])
	useEffect(() => {
		const controllHandleForm = () => {
			if (pl_ob_cod) {
				handleForm({
					ane_pl_ob_obra_cod: pl_ob_cod
				})
			}
		}
		controllHandleForm()
	}, [pl_ob_cod])
	const handleAttach = () => {
		if (input?.current?.click) {
			return input?.current?.click()
		}
	}
	const onChangeFile = async ({ target }) => {
		try {
			const { files } = target
			if (files?.length > 1 && ane_pl_ob_cod) {
				return setInfoRequest(handleWaring('Você só pode enviar um arquivo por vez'))
			}
			if (!ane_pl_ob_obs) {
				return setInfoRequest(handleWaring('Você deve escrever alguma observação antes de enviar selecionar o arquivo'))
			}
			const newItems = []
			const types_valid = []
			allowedImageExtensions.forEach(item => (
				types_valid.push({
					type: item,
				})
			))
			allowedXlsExtensions.forEach(item => (
				types_valid.push({
					type: item,
				})
			))
			const position = await getLatLng()
			for (const itemFile of files) {
				const { name } = itemFile
				const type = getExt(name)
				const { data: find_type } = find(types_valid, 'type', type)
				if (find_type) {
					const base64 = await generateBase64(itemFile)
					newItems.push({
						file_name: name,
						ane_pl_ob_cod: ane_pl_ob_cod || getUniqueRandomInt(),
						ane_pl_ob_obra_cod,
						ane_pl_ob_path: base64,
						tip_ane_desc,
						ane_pl_ob_obs,
						timestamp: generateDateTimeStampJS(),
						pl_ob_cod,
						ane_pl_ob_tipo,
						fisc_lat: position.lat,
						fisc_lng: position.lng,
						ane_pl_ob_pvs_cod
					})
				}
			}
			if (!newItems?.length) {
				return setInfoRequest(handleWaring('Você precisa adicionar pelo menos um arquivo'))
			}
			setFiles(newItems)
		} catch (error) {
			return setInfoRequest(handleError(String(error)))

		}
	}
	const handleSave = () => {
		const payload = {
			ane_pl_ob_obra_cod,
			ane_pl_ob_obs,
			ane_pl_ob_tipo,
		}
		const valid = isValid(payload)
		if (!valid) {
			return setInfoRequest(handleWaring('Você precisa preencher todos os campos'))
		}
		if (ane_pl_ob_cod) {
			payload.id = ane_pl_ob_cod
		}
		payload.pl_ob_cod = ane_pl_ob_obra_cod
		postItemAttachmentsConstructionPlan({
			...payload,
			ob_attachments: files
		})
		setFiles([])
		if (closeModal) {
			closeModal()
		}
	}

	return (
		<div className="row">
			<div className="form-group col-12">
				<label>Observação sobre o anexo:</label>
				<textarea type="text"
					className={`form-control ${!ane_pl_ob_obs && 'is-invalid'}`}
					onChange={onChange}
					name='ane_pl_ob_obs' value={ane_pl_ob_obs}
					placeholder='Descreva brevemente sobre o anexo' />
			</div>
			<SelectTypeAttachmentConstructionPlan />
			<FormSelectDiscipline
				filterServiceSubCat={filterServiceSubCat}
				services={services}
				servicesSubCat={servicesSubCat}
				addKey={getUniqueRandomInt()}
			/>
			{ane_pl_ob_path ? (
				<div className="col-md-6 col-sm-12">
					<Link to="#" className="text-decoration-none text-dark" onClick={handleAttach}>Anexar novo arquivo</Link>
				</div>
			) : ''}
			{files?.length ? <ListAttachmentsContructionPlan items={files} justPreview={true} /> : ''}
			<input
				name="file_attachments"
				className="d-none"
				type="file"
				onChange={onChangeFile}
				ref={input}
				multiple
			/>
			<div className="col-12">
				<div className="row">
					<div className="col-6">
						<button className="btn btn-info" onClick={handleSave}>
							<i className="fa fa-save"></i> Salvar
						</button>
					</div>
					{!files?.length ? (
						<div className="col-6">
							<button className="btn btn-secondary" disabled={!ane_pl_ob_obs} onClick={handleAttach}>
								<i className="fa fa-paperclip"></i> Anexar
							</button>
						</div>)
						: ''}
				</div>
			</div>
		</div>
	)
}
export default FormaAttachmentsConstructionPlan
