import React, { useContext, useEffect } from 'react'
import { requestContext } from '../../context/requestContext'
import { toast } from 'react-toastify'
import { recoverCache, storageCache } from '../../services/cacheManager'
import { namesCache } from '../../constants'

const Toasy = () => {
	const { infoRequest, setInfoRequest } = useContext(requestContext)
	useEffect(() => {
		setInfoRequest({
			status: '',
			message: '',
			color: ''
		})
	}, [])
	useEffect(() => {
		const handleClose = () => {
			setInfoRequest({
				status: '',
				message: '',
				color: ''
			})
			storageCache(namesCache.LASS_MESSAGE)
		}
		try {
			const {
				message, status
			} = infoRequest
			if (!message || !status) {
				return
			}
			const toasts = [
				{
					status: 200,
					toast: () => toast.success(message)
				},
				{
					status: 403,
					toast: () => toast.warn(message)
				},
				{
					status: 420,
					toast: () => toast.info(message)
				},
				{
					status: 500,
					toast: () => toast.error(message)
				},
			]
			const { toast: toast_function } = toasts.find(item => item.status >= status && item.status <= status)
			toast_function()
		} catch (error) {
			handleClose()
		}
	}, [infoRequest, setInfoRequest])
	return (
		<></>
	)
}
export default Toasy