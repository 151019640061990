import React, { useContext } from "react";
import { formContext } from "../../../context/formContext";
import { requestContext } from "../../../context/requestContext";
import { formatPercentage } from "../../../services/stringHandless";
const FormMilestone = ({
    handleCancel,
    callBackAfterSubmit
}) => {
    const { onChange, onChangeNumber, state } = useContext(formContext)
    const { postItemFixeReachedMilestone } = useContext(requestContext)
    const {
        mpo_cod,
        mpo_por_cen_acul,
        mpo_por_cen_acul_ant,
        mpo_por_cen_progress,
        pl_ob_cod,
        created_at
    } = state
    const handleSubmit = async () => {
        const confirm = window.confirm(`Você deseja mesmo Alterar o Marco? Ac ${formatPercentage(mpo_por_cen_acul)}, Ac. Ant. ${formatPercentage(mpo_por_cen_acul_ant)}, Progresso ${formatPercentage(mpo_por_cen_progress)}.`)
        if (!confirm) {
            return handleCancel()
        }
        const payload = {
            id: mpo_cod,
            mpo_pl_ob_cod: String(pl_ob_cod).split(',').map(mpo_pl_ob_cod => Number(mpo_pl_ob_cod)),
            mpo_por_cen_acul,
            mpo_por_cen_acul_ant,
            mpo_por_cen_progress,
        }
        if (created_at) {
            payload.created_at = created_at
        }
        const response = await postItemFixeReachedMilestone(payload)
        if (response.ok) {
            callBackAfterSubmit()
            return handleCancel()
        }
    }
    return (
        <div className="row">
            <div className="form-group col-md-3 col-sm-6">
                <label>Ac.</label>
                <input
                    type="number"
                    onChange={onChangeNumber}
                    name="mpo_por_cen_acul"
                    value={mpo_por_cen_acul}
                    min={0}
                    max={100}
                    className={`form-control ${mpo_por_cen_acul === '' && 'is-invalid'}`}
                    placeholder="0"
                />
            </div>
            <div className="form-group col-md-3 col-sm-6">
                <label>Ac. Ant.</label>
                <input
                    type="number"
                    onChange={onChangeNumber}
                    name="mpo_por_cen_acul_ant"
                    value={mpo_por_cen_acul_ant}
                    min={0}
                    max={100}
                    className={`form-control ${mpo_por_cen_acul_ant === '' && 'is-invalid'}`}
                    placeholder="0"
                />
            </div>
            <div className="form-group col-md-3 col-sm-6">
                <label>Progresso</label>
                <input
                    type="number"
                    onChange={onChangeNumber}
                    name="mpo_por_cen_progress"
                    value={mpo_por_cen_progress}
                    min={0}
                    max={100}
                    className={`form-control ${mpo_por_cen_progress === '' && 'is-invalid'}`}
                    placeholder="0"
                />
            </div>
            <div className="form-group col-md-3 col-sm-6">
                <label>Quando</label>
                <input
                    type="date"
                    onChange={onChange}
                    name="created_at"
                    value={created_at}
                    className={`form-control ${created_at === '' && 'is-invalid'}`}
                    placeholder="__/__/____"
                />
            </div>
            <div className="col-12">
                <button className="btn btn-info mr-2" onClick={handleSubmit}>
                    <i className="fa fa-save"></i> Salvar
                </button>
                <button className="btn btn-secondary" onClick={handleCancel}>
                    <i className="fa fa-times-circle"></i> Cancelar
                </button>
            </div>
        </div>
    )
}
export default FormMilestone