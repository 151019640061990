import { recoverCache } from '../services/cacheManager'

const statusCode = {
	successfull: 200,
	bad_request: 400,
	forbidden: 401,
	internal_server_error: 500
}
const namesCache = {
	DEALERSHIP: 'dealership',
	PHOTO: 'photo',
	LASS_MESSAGE: 'lass_message',
	REQUEST_SPREDSHEET_MONITORING_PAVAMENT: 'request_spredsheet_monitoring_pavement',
	AUTHENTICATED: 'authenticated',
	PROFILE: 'profile',
	API_KEY_MAP: 'api_key_map',
	REQUEST_SPREDSHEET_: 'request_spreadshet_',
	CONFIG: 'config',
	CUSTOMIZED_WHICH_PART_TO_TAKE: 'customized_which_part_to_take',
	GEOCODING: 'geocoding',
	VERSION: 'version',
	GET_APK: 'get_apk',
	ACTUAL_PAGE: 'actual_page',
	STATE: 'state',
	TIMELINE_SERVICES: 'timeline_services'
}
const nameVersion = {
	WEB: 'web',
	APP: 'app',
}

const variablesId = {
	set_cod: 35
}
const typeReport = {
	PDF: 'pdf',
	JSON: 'json',
	XLS: 'xls'
}
const dayOfWeek = [
	'Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado',
]
const defaultStatus = {
	PROGRAMADO_PEDENTE_APROVACAO: 'Pendente Aprovação'
}
/**
 * Essa função retorna os parametros de configuração da API.
 * @param string config_para_desc - Esse parametro serve para procurar por um parametro de configuração especifico.
 * @returns {Object|Array<Object>} response - Contêm os parametros de configuração obetidos da API. Quando config_para_desc é diferente de null é retornado um objeto. Quando config_para_desc é igual a null é retornado um array.
 */
async function config(config_para_desc) {
	const response = recoverCache(namesCache.CONFIG)
	if (!response) {
		return response
	}
	if (!config_para_desc) {
		return response
	}
	const findParameters = response.find(
		item => item?.config_para_desc === config_para_desc,
	)
	return findParameters
}
const typesChart = [
	{
		type: 'dealership',
		text: 'Por concessionária',
		icon: 'mr-2 fa fa-building'
	},
	{
		type: 'highway',
		text: 'Por Rodovia',
		icon: 'mr-2 fa fa-road',
	},
	{
		type: 'locale',
		text: 'Por KM',
		icon: 'mr-2 fa fa-location-arrow',
	},
	{
		type: 'service',
		text: 'Por Atividade',
		icon: 'mr-2 fa fa-list',
	},
]
const allowedXlsExtensions = [
	'xls',
	'xlsx',
	'xlsm',
	'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
	'application/vnd.ms-excel.sheet.DisciplinaEnabled.12',
	'ms-excel'
]
const allowedImageExtensions = [
	'jpeg',
	'jpg',
	'png',
	'gif',
	'bmp',
	'tiff',
	'webp'
]
const allowedMoviesExtensions = [
	'mp4'
]
const replacements = [{ search: 'bytes', replace: '' },
{ search: 'MB', replace: '' },
{ search: 'GB', replace: '' }
]
const wordsToSearch = replacements.map(item => item.search)
const DRIVERS = {
	PUBLIC: 'public',
}

const namesScreens = {
	REGISTERS: '/registers',
	USERPROFILE: '/user/profile',
	USERPASSWORD: '/user/password',
	USERLOGOUT: '/user/logout',
	OVERSIGHT: '/oversight',
	OVERSIGHT_SCHEDULED: '/oversight_scheduled',
	OVERSIGHTVIEW: '/oversight/view',
	OVERSIGHTNEW: '/oversight/new',
	HIGHWAY: '/highway',
	HIGHWAYNEW: '/highway/new',
	DEALERSHIP: '/dealership',
	DEALERSHIPNEW: '/dealership/new',
	DEALERSHIP_CONTRACT: '/dealership_contract',
	REPORTPAINEL: '/report/painel',
	SERVICE: '/service',
	SERVICENEW: '/service/new',
	DEADLINE: '/deadline',
	DEADLINENEW: '/deadline/new',
	NOTIFICATIONNEW: '/notification/new',
	NOTIFICATION: '/notification',
	NOTIFICATIONNOTIFED: '/notification/notifed',
	REPORTOVERSIGHT: '/report/oversight',
	REPORT: '/report',
	SAC: '/sac',
	REPORTOVERSIGHTMAP: '/report/oversight/map',
	SECTOR: '/sector',
	SECTORNEW: '/sector/new',
	COMPANY: '/company',
	VERSION: '/version',
	COMPANYNEW: '/company/new',
	STATUS: '/status',
	STATUSNEW: '/status/new',
	SYNC_STATUS: '/sync_status',
	SYNC_STATUSNEW: '/sync_status/new',
	HIGHWAY_LIMIT: '/highway_limit',
	HIGHWAY_LIMITNEW: '/highway_limit/new',
	COMPLEMENTS: '/complements',
	COMPLEMENTSNEW: '/complements/new',
	USERS: '/users',
	USERSNEW: '/users/new',
	WORK_OF_ART: '/work_of_art',
	WORK_OF_ARTNEW: '/work_of_art/new',
	WORK_OF_ART_TYPE: '/work_of_art_type',
	WORK_OF_ART_TYPENEW: '/work_of_art_type/new',
	WORK_OF_ART_CLASS: '/work_of_art_class',
	WORK_OF_ART_CLASSNEW: '/work_of_art_class/new',
	WORK_OF_ART_ELEMENT: '/work_of_art_element',
	WORK_OF_ART_ELEMENTNEW: '/work_of_art_element/new',
	HELP: '/help',
	HELPNEW: '/help/new',
	TUTORIAL: '/tutorial',
	TUTORIALNEW: '/tutorial/new',
	MANAGE_EMAILS: '/manage_emails',
	TYPE_SERVICE: '/type_service',
	TYPE_SERVICENEW: '/type_service/new',
	TYPE_SALES_ORDER: '/type_sales_order',
	TYPE_SALES_ORDERNEW: '/type_sales_order/new',
	TIMELINE: '/timeline',
	NOT_FOUND: '*',
	PAVEMENT: '/pavement',
	PAVEMENT_INDICATOR: '/pavement_indicators',
	PAVEMENT_INDICATOR_NEW: '/pavement_indicators/new',
	PAVEMENT_INDICATOR_VIEW: '/pavement_indicators/view',
	PAVEMENT_INDICATOR_GROUPS_INDEX: '/pavement_indicators/groups_index',
	PAVEMENT_INDICATOR_GROUPS_INDEX_NEW: '/pavement_indicators/groups_index/new',
	PAVEMENT_INDICATOR_TYPES_INDEX: '/pavement_indicators/types_index',
	PAVEMENT_INDICATOR_TYPES_INDEX_NEW: '/pavement_indicators/types_index/new',
	PAVEMENT_CHECKLIST_MANAGER: '/paviment/check_list',
	PAVEMENT_CHECKLIST_NEW: '/paviment/check_list/new',
	CONFIGURATIONS_PARAMATERS: '/configurations_paramaters',
	CONFIGURATIONS_PARAMATERSNEW: '/configurations_paramaters/new',
	DEALERSHIP_CONTRACTNEW: '/dealership_contract/new',
	REPORTOVERSIGHTVIEW: '/report/oversight/view',
	REPORTOVERSIGHTROUTINE: '/report/oversight/routine',
	REPORTOVERSIGHTROUTINEVIEW: '/report/oversight/routine/view',
	SERVICES_CONSTRUCTIONS: '/services_constructions',
	SERVICES_CONSTRUCTIONS_NEW: '/services_constructions/new',
	CONSTRUCTION_PLAN: '/construction_plan',
	CONSTRUCTION_PLANNEW: '/construction_plan/new',
	CONSTRUCTION_PLANVIEW: '/construction_plan/view',
	REPORT_CONSTRUCTION_PLAN: '/construction_plan/report',
	SPREEDSHEET_DEFAULT: '/spreedsheet_default',
	SPREEDSHEET_DEFAULTNEW: '/spreedsheet_default/new',
	ANSWER_CHECKLIST: '/answer_checklist',
	CONFIGURATIONS: '/configurations'
}
const iconsAttachments = [
	{ ext: ['pdf'], name: 'fa fa-file-pdf', color: 'danger' },
	{ ext: ['docx'], name: 'fa fa-file-word ', color: 'info' },
	{ ext: ['doc'], name: 'fa fa-save', color: 'secondary' },
	{ ext: [allowedImageExtensions], name: 'fa fa-file-image', color: 'warning' },
	{ ext: [allowedXlsExtensions], name: 'fa fa-file-excel', color: 'success' },
	{ ext: ['mp4'], name: 'fa-file-video', color: 'dark' }
]
const labelsToChartPorcenExec = ['Acumulado anterior', 'Progresso no Perido', 'A Executar']
const colorsToChartPercentageExecution = [
	'#17a2b8',
	'#E4A11B',
	'#6c757d',
	'#198754'
]
const openModal = {
	style: {
		display: 'block'
	},
	className: 'modal fade show bd-example-modal-lg'
}
const closeModal = {
	style: {
		display: 'none'
	},
	className: 'modal'
}
const liClassNameNavBarOpened = 'nav-item menu-is-opening menu-open'
const tempNavItems = [
	{
		label: 'Cadastros',
		path: '#',
		set_nivel: [0, 1],
		name_icon: 'fa fa-book',
		sub_nav_items: [
			{
				label: 'Concessionárias',
				path: `${namesScreens.REGISTERS}${namesScreens.DEALERSHIP}`,
				set_nivel: [0, 1],
				name_icon: 'far fa-circle nav-icon',
				sub_nav_items: [],
			},
			{
				label: 'Empresas',
				path: `${namesScreens.REGISTERS}${namesScreens.COMPANY}`,
				set_nivel: [0, 1],
				name_icon: 'far fa-circle nav-icon',
				sub_nav_items: [],
			},
			{
				label: 'Obra de Arte',
				path: `${namesScreens.REGISTERS}${namesScreens.WORK_OF_ART}`,
				set_nivel: [0, 1],
				name_icon: 'far fa-circle nav-icon',
				sub_nav_items: [],
			},
			{
				label: 'Rodovias',
				path: '#',
				set_nivel: [0, 1],
				name_icon: 'far fa-circle nav-icon',
				sub_nav_items: [
					{
						label: 'Rodovias',
						path: `${namesScreens.REGISTERS}${namesScreens.HIGHWAY}`,
						set_nivel: [0, 1],
						name_icon: 'far fa-circle nav-icon',
						sub_nav_items: [],
					},
					{
						label: 'Limites',
						path: `${namesScreens.REGISTERS}${namesScreens.HIGHWAY_LIMIT}`,
						set_nivel: [0, 1],
						name_icon: 'far fa-circle nav-icon',
						sub_nav_items: [],
					}
				],
			},
			{
				label: 'Setores',
				path: `${namesScreens.REGISTERS}${namesScreens.SECTOR}`,
				set_nivel: [0, 1],
				name_icon: 'far fa-circle nav-icon',
				sub_nav_items: [],
			},
			{
				label: 'Serviços',
				path: `${namesScreens.REGISTERS}${namesScreens.SERVICE}`,
				set_nivel: [0],
				name_icon: 'far fa-circle nav-icon',
				sub_nav_items: [],
			},
			{
				label: 'Usuários',
				path: `${namesScreens.REGISTERS}${namesScreens.USERS}`,
				set_nivel: [0, 1],
				name_icon: 'far fa-circle nav-icon',
				sub_nav_items: [],
			},
			{
				label: 'Pavimento',
				path: '#',
				set_nivel: [0, 1],
				name_icon: 'far fa-circle nav-icon',
				sub_nav_items: [
					{
						label: 'Grupos de Indices',
						path: `${namesScreens.REGISTERS}${namesScreens.PAVEMENT}/${namesScreens.PAVEMENT_INDICATOR_GROUPS_INDEX}`,
						set_nivel: [0, 1],
						name_icon: 'far fa-circle nav-icon',
					},
					{
						label: 'Tipos de Indices',
						path: `${namesScreens.REGISTERS}${namesScreens.PAVEMENT}/${namesScreens.PAVEMENT_INDICATOR_TYPES_INDEX}`,
						set_nivel: [0, 1],
						name_icon: 'far fa-circle nav-icon',
					},
				]
			},
			{
				label: 'Prazos',
				path: `${namesScreens.REGISTERS}${namesScreens.DEADLINE}`,
				set_nivel: [0],
				name_icon: 'far fa-circle nav-icon',
			},
			{
				label: 'Obra de Arte',
				path: `${namesScreens.REGISTERS}${namesScreens.WORK_OF_ART}`,
				set_nivel: [0, 1],
				name_icon: 'far fa-circle nav-icon',
			}
		]
	},
	{
		label: 'Conservas',
		path: '#',
		set_nivel: [0, 1, 2, 3],
		name_icon: 'fa fa-list',
		sub_nav_items: [
			{
				label: 'Gerenciar',
				path: `${namesScreens.OVERSIGHT}`,
				set_nivel: [0, 1, 2, 3],
				name_icon: 'far fa-circle nav-icon',
				sub_nav_items: [],
			},
			{
				label: 'Programadas',
				path: '#',
				set_nivel: [0, 1, 2, 3],
				name_icon: 'far fa-circle nav-icon',
				sub_nav_items: [
					{
						label: 'Criar',
						path: `${namesScreens.TIMELINE}`,
						set_nivel: [0, 1, 2, 3],
						name_icon: 'far fa-circle nav-icon',
						sub_nav_items: [],
					},
					{
						label: 'Gerenciar',
						path: `${namesScreens.OVERSIGHT_SCHEDULED}`,
						set_nivel: [0, 1, 2, 3],
						name_icon: 'far fa-circle nav-icon',
						sub_nav_items: [],
					}
				],
			},

		],
	},
	{
		label: 'Pavimento',
		path: '#',
		set_nivel: [0, 1],
		name_icon: 'fa fa-road',
		sub_nav_items: [{
			label: 'Monitoramento',
			path: `${namesScreens.PAVEMENT_INDICATOR}`,
			name_icon: 'far fa-circle nav-icon',
			set_nivel: [0, 1]
		},
		{
			label: 'Lista de checagem',
			path: `${namesScreens.PAVEMENT_CHECKLIST_MANAGER}`,
			name_icon: 'far fa-circle nav-icon',
			set_nivel: [0, 1]
		}
		],
	},
	{
		label: 'Planos de Obras',
		path: `${namesScreens.CONSTRUCTION_PLAN}`,
		set_nivel: [0, 1, 2, 3],
		name_icon: 'fa fa-building',
		sub_nav_items: [],
	}
]
const configurationsLinks = [
	{
		title: 'Sincronização',
		subtitle: '',
		icon: 'sync',
		set_nivel: [0, 1, 2, 3],
		path: namesScreens.SYNC
	},
	{
		title: 'Versões do sistema',
		subtitle: 'Versões atuais para Web e App',
		icon: 'spinner',
		set_nivel: [0, 1, 2, 3],
		path: namesScreens.VERSION
	},
	{
		title: 'Complementos',
		subtitle: 'Acostamento, Alça/Ramo...',
		icon: 'th-large',
		set_nivel: [0],
		path: namesScreens.COMPLEMENTS
	},
	{
		title: 'Gerenciar e-mails',
		subtitle: 'Gerenciar e-mails',
		icon: 'envelope',
		set_nivel: [0, 1, 2, 3],
		path: namesScreens.MANAGE_EMAILS
	},
	{
		title: 'Parâmetros',
		subtitle: 'Gerenciar e Cadastrar Parâmetros',
		icon: 'sliders-h',
		set_nivel: [0],
		path: namesScreens.CONFIGURATIONS_PARAMATERS
	},
	{
		title: 'Status para ocorrências',
		subtitle: 'Executado, Pendente, Notificada,...',
		icon: 'info-circle',
		set_nivel: [0],
		path: `${namesScreens.REGISTERS}${namesScreens.STATUS}`
	},
	{
		title: 'Status para sincronização',
		subtitle: 'Pendente, Sincronizado, Erro',
		icon: 'check-circle',
		set_nivel: [0],
		path: `${namesScreens.REGISTERS}${namesScreens.SYNC_STATUS}`
	},
	{
		title: 'Tipos de serviços',
		subtitle: 'Emergencial, Programável, ...',
		icon: 'layer-group',
		set_nivel: [0],
		path: namesScreens.TYPE_SERVICE
	},
	{
		title: 'Tipos de NCs',
		subtitle: 'Conservação de Rotina, Plano...',
		icon: 'file',
		set_nivel: [0],
		path: namesScreens.TYPE_SALES_ORDER
	},
	{
		title: 'Planilhas modelo - obras',
		subtitle: 'Dispositivos de Entrocamento, Duplicação...',
		icon: 'columns',
		set_nivel: [0, 1],
		path: namesScreens.SPREEDSHEET_DEFAULT
	}
]
const buttosExportReport = [
	{
		id: 0,
		label: 'Planilha',
		icon: 'fa fa-table',
		router_screen: ''
	},
	{
		id: 1,
		label: 'PDF Completo',
		icon: 'fa fa-file',
		router_screen: namesScreens.OVERSIGHTVIEW
	},
	{
		id: 2,
		label: 'PDF Simplicado',
		icon: 'fa fa-file',
		router_screen: namesScreens.OVERSIGHTVIEW
	}
]
export {
	statusCode,
	namesCache,
	nameVersion,
	variablesId,
	typeReport,
	dayOfWeek,
	defaultStatus,
	typesChart,
	allowedXlsExtensions,
	allowedImageExtensions,
	allowedMoviesExtensions,
	replacements,
	wordsToSearch,
	DRIVERS,
	namesScreens,
	iconsAttachments,
	labelsToChartPorcenExec,
	colorsToChartPercentageExecution,
	openModal,
	closeModal,
	liClassNameNavBarOpened,
	tempNavItems,
	config,
	configurationsLinks,
	buttosExportReport
}