import React, { useContext, useEffect, useRef, useState } from 'react'
import { authContext } from '../../../context/authContext'
import { formContext } from '../../../context/formContext'
import { Link } from 'react-router-dom'
const Login = () => {
	const { onChange, state } = useContext(formContext)
	const { setInfoRequest, login, showSpinner } = useContext(authContext)
	const inputRef = useRef(null)
	useEffect(() => {
		const controllFocus = () => {
			if (inputRef?.current?.focus) {
				inputRef.current.focus()
			}
		}
		controllFocus()
	}, [inputRef])
	const {
		email,
		password,
	} = state
	const [type, setType] = useState('password')
	const handleSubmit = (e) => {
		e.preventDefault()
		e.stopPropagation()
		if (!email || !password) {
			return handleWaring()
		}
		login({
			email,
			password
		})
	}
	const handleWaring = () => {
		setInfoRequest({
			status: 400,
			message: 'Você precisa preencher seu e-mail e senha',
		})
	}
	return (
		<form onSubmit={handleSubmit}>
			<div className="input-group mb-3">
				<input type="email" className="form-control"
					onChange={onChange} value={email} name='email'
					placeholder="Seu e-mail" ref={inputRef} />
				<div className="input-group-append">
					<div className="input-group-text">
						<span className="fas fa-envelope"></span>
					</div>
				</div>
			</div>
			<div className="input-group mb-3">
				<input type={type} className="form-control"
					onChange={onChange} value={password} name='password'
					placeholder="Sua senha" />
				<div className="input-group-append">
					<div className="input-group-text" onClick={() => setType(type === 'password' ? 'text' : 'password')}>
						<span className={password?.length > 3 ? type === 'text' ? 'fa fa-eye' : 'fa fa-eye-slash' : 'fas fa-lock'}></span>
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col-12 mb-3">
					{
						showSpinner ?
							<button type="submit" className="btn btn-info btn-block">
								<div className="spinner-border spinner-border-sm" role="status" />
							</button>
							:
							<button type="submit" className="btn btn-info btn-block">
								Entrar <i className="fas fa-sign-out-alt"></i>
							</button>
					}
				</div>
				<div className='col-md-6 col-sm-12 mb-3'>
					Esqueceu a senha ?
				</div>
				<div className='col-md-6 col-sm-12 mb-3'>
					<Link to="/forgot_password" className='text-secondary'>
						Recuperar senha
					</Link>
				</div>
			</div>
		</form>
	)
}
export default Login