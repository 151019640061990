import React, { useContext, useEffect, useState } from 'react'
import { changeUnit, enableDoubleClickToCopy, formatCurrency, formatPercentage } from '../../../../services/stringHandless'
import FormServiceConstructionPlan from '../../../form/serviceConstructionPlan'
import { formContext } from '../../../../context/formContext'
import { Link, useLocation } from 'react-router-dom'
import { authContext } from '../../../../context/authContext'
import ServiceAdvancement from '../../../modal/serviceAdvancement'
import { formatDate } from '../../../../services/dateHandless'
import FormApprovedRecusedProgress from '../../../form/approvedRecusedProgressConstructionPlan'
import { closeModal, namesCache, namesScreens, openModal } from '../../../../constants'
import { requestContext } from '../../../../context/requestContext'
import { handleSuccess } from '../../../../services/isValid'
import { storageCache } from '../../../../services/cacheManager'
import RenderHeaderModal from './RenderHeaderModal'
const RenderTH = ({ item, enabledEdit }) => {
	const { handleForm, state } = useContext(formContext)
	const { setInfoRequest } = useContext(requestContext)
	const { serv_desc, serv_sub_cat_desc, pvs_obs } = state
	const { user } = useContext(authContext)
	const [openModalAdvance, setOpenModalAdvance] = useState(closeModal)
	const [openModalEdit, setOpenModalEdit] = useState(closeModal)
	const [openModalFisc, setOpenModalFisc] = useState(closeModal)
	const location = useLocation()
	const [disabled, setDisabled] = useState(false)
	useEffect(() => {
		const controllDisabledInputs = () => {
			const { pathname } = location
			if (pathname === namesScreens.REPORT_CONSTRUCTION_PLAN) {
				return setDisabled(true)
			}
			return setDisabled(false)
		}
		controllDisabledInputs()
	}, [location])
	const handleEdit = () => {
		handleForm({
			...item,
			ane_pl_ob_pvs_cod: item.prog_serv_serv_cod,
		})
		if (user?.set_nivel === 0) {
			return setOpenModalEdit(openModal)
		}
		setOpenModalAdvance(openModal)
	}
	const handleProgress = () => {
		handleForm({
			...item,
			ane_pl_ob_pvs_cod: item.prog_serv_serv_cod,
		})
		setOpenModalAdvance(openModal)
	}
	const handleClear = () => {
		handleForm({
			pvs_obs: '',
			prog_serv_qtd_acul: '',
			prog_serv_percen_progress: '',
			ob_serv_med_desc: '',
			prog_serv_percen_acul: '',
		})
		setOpenModalAdvance(closeModal)
		setOpenModalEdit(closeModal)
		setOpenModalFisc(closeModal)
	}
	const handleFisc = () => {
		if (user?.set_nivel !== 0 || !item?.pvs_fisc) {
			return
		}
		const { fisc_updated_at } = item
		handleForm({
			...item,
			updated_at: fisc_updated_at,
			fisc_cod: item.pvs_fisc
		})
		setOpenModalFisc(openModal)
	}
	const onDoubleClick = async (event) => {
		const result = await enableDoubleClickToCopy(event.target)
		if (result) {
			storageCache(namesCache.LASS_MESSAGE)
			return setInfoRequest(handleSuccess(`Texto ${result} copiado para área de transferência`))
		}
	}
	return (
		<>
			<tr>
				{item.prog_serv_obra ? (
					<th scope="row">#{item.prog_serv_obra}</th>
				) : null}

				<td colSpan={item.prog_serv_obra ? 1 : 2} onDoubleClick={onDoubleClick}>
					{item.pvs_obs}
				</td>
				<td>
					{!enabledEdit ? (
						<span className="badge" onClick={handleFisc} style={{
							backgroundColor: item.st_cor_valor,
							cursor: 'pointer'
						}}>{item.st_desc}</span>
					) : (
						<span className="badge" style={{
							backgroundColor: item.st_cor_valor,
						}}>{item.st_desc}</span>
					)}
				</td>
				<td>
					{item.serv_sub_cat_desc}
				</td>
				<td>
					{formatCurrency(item.pvs_qtd, false)} {changeUnit(item?.ob_serv_med_desc)}
				</td>
				<td>
					{formatCurrency(item.prog_serv_qtd_acul_ant, false)} {changeUnit(item?.ob_serv_med_desc)}
				</td>
				<td>
					{formatCurrency(item.prog_serv_qtd_acul - item.prog_serv_qtd_acul_ant, false)} {changeUnit(item?.ob_serv_med_desc)}
				</td>
				<td>
					{!enabledEdit ? (
						<Link to={'#'}
							className="text-dark text-decoration-none"
							onClick={handleProgress}
						>
							{formatCurrency(item.prog_serv_qtd_acul, false)} {changeUnit(item?.ob_serv_med_desc)}
						</Link>
					) : (
						`${formatCurrency(item.prog_serv_qtd_acul, false)} ${changeUnit(item?.ob_serv_med_desc)}`
					)}
				</td>
				<td>
					{formatDate(item.apos_data || item.created_at || item.updated_at)}
				</td>
				<td>
					<>
						{user?.set_nivel === 0 ? (
							<button
								className="btn btn-info btn-sm mr-2"
								onClick={handleEdit}
								disabled={enabledEdit}
							>
								<i className="fa fa-edit"></i>
							</button>
						) : (
							<button className="btn btn-info btn-sm mr-2"
								onClick={handleProgress}
								disabled={enabledEdit}
							>
								<i className="fa fa-arrow-right"></i>
							</button>
						)}
					</>
				</td>
			</tr>
			<div className={openModalAdvance.className} id={`ServiceAdvancement${item.prog_serv_cod}`} style={openModalAdvance.style}
				tabIndex="-1" role="dialog" aria-labelledby={`ServiceAdvancementLabel${item.prog_serv_cod}`} aria-hidden="true">
				<div className="modal-dialog modal-lg">
					<div className="modal-content">
						<div className="modal-header">
							<RenderHeaderModal
								prog_serv_cod={item.prog_serv_cod}
								pvs_obs={pvs_obs}
								serv_desc={serv_desc}
								serv_sub_cat_desc={serv_sub_cat_desc}
							/>
							<button type="button" onClick={handleClear} className="close" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div className="modal-body">
							<ServiceAdvancement closeModal={handleClear} />
						</div>
					</div>
				</div>
			</div>
			<div className={openModalEdit.className} id={`FormServiceConstructionPlan${item.prog_serv_cod}`} style={openModalEdit.style} tabIndex="-1" role="dialog" aria-labelledby="FormServiceConstructionPlanLabel" aria-hidden="true">
				<div className="modal-dialog modal-lg">
					<div className="modal-content">
						<div className="modal-header">
							<RenderHeaderModal
								prog_serv_cod={item.prog_serv_cod}
								pvs_obs={pvs_obs}
								serv_desc={serv_desc}
								serv_sub_cat_desc={serv_sub_cat_desc}
							/>
							<button type="button" onClick={handleClear} className="close" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div className="modal-body">
							<FormServiceConstructionPlan closeModal={handleClear} />
						</div>
					</div>
				</div>
			</div>
			<div className={openModalFisc.className} id={`FormApprovedRecusedProgress${item.prog_serv_cod}`} style={openModalFisc.style} tabIndex="-1" role="dialog" aria-labelledby="FormApprovedRecusedProgressLabel" aria-hidden="true">
				<div className="modal-dialog modal-lg">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title" id={`FormApprovedRecusedProgressLabel${item.prog_serv_cod}`}>
								{serv_desc} | {serv_sub_cat_desc}
							</h5>
							<button type="button" onClick={handleClear} className="close" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div className="modal-body">
							<FormApprovedRecusedProgress closeModal={handleClear} />
						</div>
					</div>
				</div>
			</div>
		</>
	)
}
export default RenderTH