import React, { useContext, useEffect, useState } from 'react'
import RenderTH from './RenderTH'
import RenderSumTH from './RenderSumTH'
import { formContext } from '../../../context/formContext'
import { prepareDataToBeDisplayedInTable } from '../../../services/constructionPlan'
import { namesCache } from '../../../constants'
import { recoverCache, storageCache } from '../../../services/cacheManager'
const ListServiceConstructionPlan = ({ items }) => {
	const [services, setServices] = useState(null)
	const { state } = useContext(formContext)
	const [disabled, setDisabled] = useState(false)
	const defaultPlaceholderAgroupDisciplines = 'Digite aqui como deve ser agrupado o código da disciplina'
	const [placeholderAgroupDisciplines, setPlaceHolderAgroupDisciplines] = useState(defaultPlaceholderAgroupDisciplines)
	const [whichPartToTake, setWhichPartToTake] = useState(2)
	const [pl_ob_cod, setPlObCod] = useState('')
	const generateKeyCacheCustomizedWhichPartToTake = () => {
		const key_cache = pl_ob_cod
		return `${namesCache.CUSTOMIZED_WHICH_PART_TO_TAKE}_${key_cache}`
	}
	useEffect(() => {
		const recoverCustomizedWichPartToTake = () => {

			const recover = recoverCache(generateKeyCacheCustomizedWhichPartToTake())
			if (recover) {
				const { value } = recover
				onChangeWhichPartToTake({ target: { value } })
				setPlaceHolderAgroupDisciplines(value)
			}
		}
		recoverCustomizedWichPartToTake()
	}, [pl_ob_cod])
	const {
		apos_data_from,
		apos_data_to
	} = state
	useEffect(() => {
		const controllDisabledInputs = () => {
			if (apos_data_from && apos_data_to) {
				return setDisabled(true)
			}
			return setDisabled(false)
		}
		controllDisabledInputs()
	}, [apos_data_from, apos_data_to])
	useEffect(() => {
		const setItems = async () => {
			if (items?.length && whichPartToTake) {
				setPlObCod(items[items?.length - 1].prog_serv_obra)
				const itemsWithCodigo = await prepareDataToBeDisplayedInTable(items, whichPartToTake)
				setServices(itemsWithCodigo)
			}
		}
		setItems()
	}, [items, whichPartToTake])
	const onChangeWhichPartToTake = ({ target: { value } }) => {
		if (value) {
			const qtdDots = String(value).split('.').length
			setWhichPartToTake(qtdDots)
			setPlaceHolderAgroupDisciplines(value)
		} else {
			setPlaceHolderAgroupDisciplines(defaultPlaceholderAgroupDisciplines)
		}
		storageCache(generateKeyCacheCustomizedWhichPartToTake(), { value })
	}
	if (!Array.isArray(items) || !services) {
		return <p className="text-secondary">Não há serviços para serem listados</p>
	}
	return (
		<div className='container-fluid'>
			<div className="col-12 mb-3">
				<label>Agrupar os itens:</label>
				<input className='form-control'
					placeholder={placeholderAgroupDisciplines}
					onBlur={onChangeWhichPartToTake} />
			</div>
			<div className="table-responsive">
				{services ? Object.keys(services).map(nameGroup => (
					<table className="table table-bordered">
						<thead>
							<RenderSumTH items={services[nameGroup]} />
							<tr>
								<th scope="col">N° Obra</th>
								<th scope="col">Código</th>
								<th scope="col">
									Situação
								</th>
								<th scope="col">Descrição</th>
								<th scope="col">Qtd. Prev.</th>
								<th scope="col">Qtd. Exec. Ant.</th>
								<th scope="col">Progresso</th>
								<th scope="col">Qtd. Exec.</th>
								<th scope="col">Quando</th>
								<th scope="col"></th>
							</tr>
						</thead>
						<tbody>
							{services[nameGroup].sort((a, b) => a.codigo - b.codigo).map(item => (
								<RenderTH key={item.prog_serv_cod} item={item} enabledEdit={disabled} />
							))}
						</tbody>
					</table>
				)) : ''}
			</div>
		</div>
	)
}
export default ListServiceConstructionPlan