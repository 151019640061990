import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { styles } from './styles';
import { buttosExportReport, namesCache } from '../../../../constants';
import { requestContext } from '../../../../context/requestContext';
import { handleWaring } from '../../../../services/isValid';
import { storageCache } from '../../../../services/cacheManager';
import { formContext } from '../../../../context/formContext';
const ButtonsExportReport = ({
    fetchItems,
    resetValues,
    generateDate,
    selectedItems,
    selectAll,
    setSelectAll
}) => {
    const navigate = useNavigate()
    const { handleForm } = useContext(formContext)
    const { setInfoRequest, oversight } = useContext(requestContext)
    const [openDropDown, setOpenDropDown] = useState('')
    const [disabledCheckBox, setDisableCheckBox] = useState(true)
    useEffect(() => {
        const controllDisableCheckBox = () => {
            if (oversight?.data?.length) {
                return setDisableCheckBox(false)
            }
            return setDisableCheckBox(true)
        }
        controllDisableCheckBox()
    }, [oversight])
    const handleOpenDropDown = () => {
        if (openDropDown === '') {
            return setOpenDropDown('show')
        }
        return setOpenDropDown('')
    }
    const handleExportReport = ({ router_screen, id }) => {
        if (router_screen) {
            if (!selectedItems?.length) {
                setInfoRequest(handleWaring('Você deve pelo menos selecionar uma Nc para poder visualizar o relatório'))
                return storageCache(namesCache.LASS_MESSAGE)
            }
            handleForm({
                ped_venda_cod: selectedItems.join(',')
            })
            return navigate(router_screen, {
                state: {
                    id_type_report: id
                }
            })
        }
        if (generateDate) {
            return generateDate()
        }
    }
    return (
        <div className='card sticky-top'>
            <div className='card-body'>
                <div className='col-12'>
                    <div className='row'>
                        <div className='mb-3 col-md-6 col-sm-6'>
                            <button className='btn btn-info btn-sm mr-3' onClick={fetchItems}>
                                Aplicar filtro <i className='fa fa-filter'></i>
                            </button>
                        </div>
                        <div className='mb-3 col-md-6 col-sm-6'>
                            <button className='btn btn-dark mr-3 btn-sm' onClick={resetValues}>
                                Limpar filtros <i className='fa fa-eraser'></i>
                            </button>
                        </div>
                        <div className='mb-3 col-md-6 col-sm-6'>
                            <div className='dropdown' onClick={handleOpenDropDown}>
                                <button className={`btn btn-success dropdown-toggle btn-sm ${openDropDown}`} type='button' id='menuExportReport' data-bs-toggle='dropdown' aria-expanded='false'>
                                    Exportar <i className='fa fa-download' aria-hidden='true'></i>
                                </button>
                                <ul className={`dropdown-menu ${openDropDown}`}
                                    aria-labelledby='menuExportReport'
                                    style={openDropDown === 'show' ? { ...styles } : {}}
                                >
                                    {buttosExportReport.map(item => (
                                        <li key={item.id}>
                                            <span style={{
                                                cursor: 'pointer'
                                            }} onClick={() => handleExportReport(item)} className='dropdown-item'>
                                                {item.label} <i className={item.icon}></i>
                                            </span>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        <div className='mb-3 col-md-6 col-sm-12'>
                            <div class="form-check">
                                <input
                                    className="form-check-input"
                                    onChange={() => setSelectAll(!selectAll)}
                                    type="checkbox"
                                    value={selectAll}
                                    id="selectAll"
                                    checked={selectAll}
                                    disabled={disabledCheckBox}
                                />
                                <label className="form-check-label" htmlFor="selectAll">
                                    Selecionar todos registro(s)
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ButtonsExportReport