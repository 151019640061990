import React, { useContext } from 'react'
import { formContext } from '../../../context/formContext'
import { requestContext } from '../../../context/requestContext'
import { authContext } from '../../../context/authContext'
const SearchOversight = ({ handleNew, toggleSidebar = false, typeOversight = 0 }) => {
	const { state, onChange } = useContext(formContext)
	const { getItemOversight } = useContext(requestContext)
	const { user } = useContext(authContext)
	const {
		desc, ped_venda_status, serv_cat_prazo_from, serv_cat_prazo_to, conc_cod, type_date
	} = state
	const handleSearch = () => {
		return getItemOversight({
			id: ped_venda_status,
			conc_cod: conc_cod,
			ped_venda_date_from: serv_cat_prazo_from,
			ped_venda_date_to: serv_cat_prazo_to,
			desc,
			type_date: type_date,			
		})
		
	}
	const onClick = () => {
		getItemOversight({
			id: ped_venda_status,
			conc_cod: conc_cod,
			ped_venda_date_from: serv_cat_prazo_from,
			ped_venda_date_to: serv_cat_prazo_to,
			type_date: type_date,
			ped_venda_tip: typeOversight
		})
	}
	return (
		<div className="input-group input-group-sm mb-3">
			<div className="input-group-append">
				{toggleSidebar&&<button className="btn btn-tool btn-sm" onClick={toggleSidebar}>
					Filtrar
				</button>}
				<button className="btn btn-tool btn-sm" onClick={onClick}>
					Atualizar lista
				</button>
			</div>
			{user?.set_nivel <= 2 && (
				<div className="input-group-append">
					<button className="btn btn-tool btn-sm" onClick={handleNew}>
						<i className="fas fa-plus"></i> Novo
					</button>
				</div>
			)}
			<input type="text" className="form-control float-right"
				name="desc" value={desc} onChange={onChange}
				placeholder="Procurar por  concessionaria ou Rodovia" />
			<div className="input-group-append">
				<button className="btn btn-default" onClick={handleSearch}>
					<i className="fas fa-search"></i>
				</button>
			</div>
		</div>
	)
}
export default SearchOversight