import React, { useContext, useEffect, useRef, useState } from 'react';
import { formContext } from '../../../../context/formContext';
import { requestContext } from '../../../../context/requestContext';
import { config } from '../../../../constants';
import { find } from '../../../../services/arrayHandless';

const FilterStatusOversight = ({ multiple = true }) => {
  const { handleForm, state: { ped_venda_status } } = useContext(formContext);
  const { status } = useContext(requestContext);
  const inputRef = useRef();
  
  const [selectedStatus, setSelectedStatus] = useState([]);

  useEffect(() => {
    const loadConfig = async () => {
      const config_ = await config('ped_venda_status_pendente_execucao');
      if (!config_ || !status?.data) {
        return;
      }
      const { config_para_value } = config_;
      if (config_para_value) {
        const { data: findStatus } = find(status.data, 'st_cod', Number(config_para_value));
        if (findStatus) {
          setSelectedStatus([findStatus.st_cod]); // Atualiza estado local
          handleForm({
            ...findStatus,
            ped_venda_status: findStatus.st_cod,
          });
        }
      }
    };
    loadConfig();
  }, [status]);

  const handleChange = (event) => {
    try {
      const selectedValues = Array.from(event.target.selectedOptions).map(opt => Number(opt.value));
      setSelectedStatus(selectedValues); // Atualiza estado local
      handleForm({ ped_venda_status: selectedValues.join(',') });
    } catch (error) {
      console.log(error);
    }
  };

  const RenderOption = ({ item }) => {
    const isSelected = selectedStatus.includes(item.st_cod);
    return (
      <option className={isSelected ? 'bg-info text-light' : ''} key={item.st_cod} value={item.st_cod}>
        {item.st_desc}
      </option>
    );
  };

  return (
    <div className="form-group">
      <label>Situação</label>
      <select
        ref={inputRef}
        className="form-control"
        multiple={multiple}
        value={selectedStatus} 
        onChange={handleChange} 
      >
        <option value={0}>Todas as Situações</option>
        {Array.isArray(status?.data) && status.data.map(item => <RenderOption key={item.st_cod} item={item} />)}
      </select>
    </div>
  );
};

export default FilterStatusOversight;
