import React, { useContext, useEffect, useState } from 'react'
import { formatPercentage } from '../../../../../services/stringHandless'
import { formContext } from '../../../../../context/formContext'
import { formatDate, formateValidatedDate } from '../../../../../services/dateHandless'
const RenderTH = ({ item, handleEdit, handleDelete }) => {
	const [possibleEdit, setPossibleEdit] = useState(true)
	const { state, handleForm } = useContext(formContext)
	const { created_at, apos_data_to } = state
	useEffect(() => {
		setPossibleEdit(((handleEdit !== null) && (handleDelete !== null)))
	}, [handleEdit, handleDelete])
	useEffect(() => {
		const setDefaultValueCreatedAt = () => {
			if (apos_data_to) {
				handleForm({
					created_at: formatDate(apos_data_to)
				})
			}
		}
		setDefaultValueCreatedAt()
	}, [possibleEdit, apos_data_to])
	const onBlur = ({ target: { value } }) => {
		handleForm({
			created_at: formatDate(value)
		})
	}
	const handleClear = () => {
		handleForm({
			created_at: ''
		})
	}
	return (
		<>
			<tr>
				<td>{formatPercentage(item.mpo_por_cen_acul)}</td>
				<td>{formatPercentage(item.mpo_por_cen_acul_ant)}</td>
				<td>{formatPercentage(item.mpo_por_cen_progress)}</td>
				{!possibleEdit ? (
					<td onClick={handleClear} style={{
						cursor: 'pointer'
					}}>
						{created_at ? created_at : (
							<input type='date'
								defaultValue={formateValidatedDate(item.created_at)}
								name='created_at'
								onBlur={onBlur}
							/>)}
					</td>
				) : (
					<td>{item.created_at}</td>
				)}
				{possibleEdit ? (
					<td>
						<button onClick={() => handleEdit(item)} className='btn btn-info btn-sm mr-2'>
							<i className='fa fa-edit'></i>
						</button>
						<button onClick={() => handleDelete(item)} className='btn btn-secondary btn-sm'>
							<i className='fa fa-trash'></i>
						</button>
					</td>
				) : null}
			</tr>
		</>
	)
}
export default RenderTH