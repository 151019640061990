import React, { useContext, useEffect } from 'react'
import { formContext } from '../../../context/formContext'
import { requestContext } from '../../../context/requestContext'
import { authContext } from '../../../context/authContext'
import { ctype_digit } from '../../../services/stringHandless'
const SearchContructionPlan = ({ handleNew }) => {
	const { state, onChange, handleForm } = useContext(formContext)
	const { getItemConstructionPlan } =
		useContext(requestContext)
	const { user } = useContext(authContext)
	const {
		pl_ob_cod,
		desc,
		conc_cod,
		rod_cod,
		ped_venda_date_init,
		ped_venda_date_fim,
		type_date } = state
	useEffect(() => {
		const verifyisNumber = () => {
			if (!desc) {
				return handleForm({
					pl_ob_cod: 0
				})
			}
			if (ctype_digit(desc)) {
				handleForm({
					pl_ob_cod: desc
				})
			} else {
				handleForm({
					pl_ob_cod: 0,
				})
			}
		}
		verifyisNumber()
	}, [desc])
	const onSearch = () => {
		getItemConstructionPlan({
			id: pl_ob_cod,
			ped_venda_concessionaria: conc_cod,
			rod_cod,
			ped_venda_date_init,
			ped_venda_date_fim,
			desc: ctype_digit(desc) ? '' : desc,
			type_date: type_date || 8
		})
	}

	return (
		<div className="input-group input-group-sm">
			{user?.set_nivel <= 3 && (
				<div className="input-group-append">
					<button className="btn btn-tool btn-sm" onClick={handleNew}>
						Novo <i className="fa fa-plus"></i>
					</button>
				</div>
			)}
			<div className="input-group-append">
				<button className="btn btn-tool btn-sm" onClick={onSearch}>
					Atualizar lista
				</button>
			</div>
			<input
				type="text"
				className="form-control float-right"
				name="desc"
				value={desc}
				onChange={onChange}
				placeholder="Procurar por concessionária"
			/>
			<div className="input-group-append">
				<button className="btn btn-default" onClick={onSearch}>
					<i className="fas fa-search"></i>
				</button>
			</div>
		</div>
	)
}
export default SearchContructionPlan
