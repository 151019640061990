import React, { useContext, useEffect, useRef } from "react";
import { formContext } from "../../../../../context/formContext";
import { styleButtonSearch, styleButtonSearchLeft } from "../../../../../css/globlalStyle";
import { formateValidatedDate } from "../../../../../services/dateHandless";
import { requestContext } from "../../../../../context/requestContext";
const FilterDateConstructionPlanProgressService = ({
    fechItems,
    additionalLabel1 = '',
    additionalLabel2 = ''
}) => {
    const { handleForm, state } = useContext(formContext)
    const { loading } = useContext(requestContext)
    const refAposDataFrom = useRef(null)
    const refAposDataTo = useRef(null)
    const {
        apos_data_from,
        apos_data_to
    } = state
    useEffect(() => {
        const controlShowValue = () => {
            if (apos_data_from && refAposDataFrom.current) {
                refAposDataFrom.current.value = apos_data_from
            }
            if (apos_data_to && refAposDataTo?.current) {
                refAposDataTo.current.value = apos_data_to
            }
        }
        controlShowValue()
    }, [apos_data_from, apos_data_to])
    const onBlur = ({
        target: {
            value, name
        }
    }) => {
        handleForm({
            [name]: value
        })
        if ((name === 'apos_data_to') && !value) {
            handleForm({
                [name]: formateValidatedDate()
            })
        }
    }
    const handleClear = () => {
        handleForm({
            apos_data_from: '',
            apos_data_to: '',
        })
        refAposDataFrom.current.value = ''
        refAposDataTo.current.value = ''
    }
    return (
        <div className="row">
            <div className="form-group col-md-6 col-sm-12">
                <label>Data {additionalLabel1 ? additionalLabel1 : 'inicial'}:</label>
                <div className="input-group">
                    <div className="input-group-prepend">
                        <span className="input-group-text bg-info"
                            style={styleButtonSearchLeft}
                            onClick={handleClear}>
                            <i className="fa fa-times-circle" aria-hidden="true"></i>
                        </span>
                    </div>
                    <input
                        ref={refAposDataFrom}
                        type="date"
                        className="form-control"
                        name="apos_data_from"
                        onBlur={onBlur}
                        disabled={loading}
                    />
                </div>
            </div>
            <div className="form-group col-md-6 col-sm-12">
                <label>Data {additionalLabel2 ? additionalLabel2 : 'final'}:</label>
                <div className="input-group">
                    <input
                        ref={refAposDataTo}
                        type="date"
                        className="form-control"
                        name="apos_data_to"
                        onBlur={onBlur}
                        disabled={loading}
                    />
                    <div className="input-group-prepend">
                        <span className="input-group-text bg-info"
                            style={styleButtonSearch}
                            onClick={fechItems}>
                            <i className="fa fa-search" aria-hidden="true"></i>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default FilterDateConstructionPlanProgressService